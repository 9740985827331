import React, {Component, useEffect, useState} from 'react';
import {getPaymentLinkCall, getPaymentStatusCall} from "../api/ApiCalls";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {hasAValue} from "../uitls/SharedFunctions";
import {toastOptions} from "../config/constants";
import {stringsTranslate} from "../locales/CustomTranslater";
import {words} from "../locales/Words";
import {getAnalytics, logEvent} from "firebase/analytics";


export default function StickerOrderPage({match}) {
    const [paid, setPaid] = useState(undefined)
    const [paymentLink, setPaymentLink] = useState(undefined)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loading) {
            Swal.close();
        }
    }, [loading]);

    useEffect(() => {
        launchLoadingModal();
        getPaymentStatus(match.params.code)
    }, [])

    const analytics = getAnalytics();
    logEvent(analytics, "Sticker order page")
    return (
        <div className="inner inner-pages">
            <div className="main">
                <section id="home" className="section welcome-area inner-area bg-overlay min-vh-100 overflow-hidden">
                    <div className="container h-100">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-12 col-md-7">
                                {hasAValue(paid) && <div className="welcome-intro error-area text-center">
                                    <h2 className="text-white">{paid?stringsTranslate(words.words.thank_you):stringsTranslate(words.words.continue_to_pay)}</h2>
                                    <h3 className="text-white my-4">{paid && stringsTranslate(words.words.you_will_receive_another_email_with_information_about_your_order)}</h3>
                                    <h3 className="text-white my-4">{stringsTranslate(words.words.redirected_after3)}</h3>
                                    {/*<a href={paid? "/vehicle-info/"+match.params.code: paymentLink} className="btn sApp-btn text-uppercase">{paid?stringsTranslate(words.words.go_to_your_car_page):stringsTranslate(words.words.continue_to_pay)}</a>*/}
                                </div>}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );


    function launchLoadingModal() {
        // Show loading Swal
        Swal.fire({
            title: "Loading...",
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
    }

    function getPaymentStatus(code) {
        getPaymentStatusCall(code).then(result => {
            setLoading(false);
            if (result.paid) {
                toast.success(result.message, toastOptions)
                setPaid(true)
                setTimeout(() => {
                    window.location = "/vehicle-info/"+match.params.code
                }, 5000)
            } else {
                toast.success(result.message, toastOptions)
                setPaid(false)
                setPaymentLink(result.link)
                setTimeout(() => {
                    window.location = result.link
                }, 5000)
            }

        }).catch(error => {
            toast.success(error.response.data.message, toastOptions)

        })
    }
}
