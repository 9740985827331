import React, {useEffect} from 'react';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'
import Header from "./components/HeaderSection/Header";
import {saveDataLocalStorage} from "./uitls/LocalStorageFunctions";
import {appLocalStoargeValues} from "./config/constants";

const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export default function App() {

    useEffect(()=>{
        logEvent(analytics, "open_website")
    },[])

    function switchLangauge(language){
        saveDataLocalStorage(appLocalStoargeValues.language, language)
        window.location.reload();
    }

  return (
    <>
        <Header switchLangauge={(language)=> switchLangauge(language)} imageData={"/img/logo/logoNoBackgroundWhite.png"}/>
        <ToastContainer limit={2}/>
        <MyRouts/>
    </>
  );
}
