import {appUrls,appLocalStoargeValues} from "../config/constants";
import axios from "axios";
import {getDataLocalStorage} from "../uitls/LocalStorageFunctions";
import {getLanguage} from "../uitls/SharedFunctions";

const apiLocation = appUrls.backend_api


export const apiGet = async (route, options = {}, headersReceived = (headers) => {
}) => {
    let userData = await getDataLocalStorage(appLocalStoargeValues.userData)
    return axios.get(apiLocation + route,{
        headers:{
            'Authorization': userData === null ? "fg" : "Barear "+ userData.access_token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'language': getLanguage(),
        }}).then(response => {
        return response.data;
    })
        .catch(error => {
            throw error;
        });
};

export const apiDelete = async (route, data = {}) => {
    let userData = await getDataLocalStorage(appLocalStoargeValues.userData)
    return axios.post(apiLocation + route, data,{
        headers:{
            'Authorization': userData === null ? "fg" : "Barear "+ userData.access_token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'language': getLanguage(),
        }})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const apiPut = async (route, data, options = {}) => {
    let userData = await getDataLocalStorage(appLocalStoargeValues.userData)
    return axios.put(apiLocation + route, data,{
        headers:{
            'Authorization': userData === null ? "fg" : "Barear "+ userData.access_token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'language': getLanguage(),
        }})
        .then(response => {
            return response.data;
        })
        .catch(error => {
            throw error;
        });
};

export const apiPost = async (route, data, options = {}) => {
    let userData = await getDataLocalStorage(appLocalStoargeValues.userData)
    return axios.post(apiLocation + route, data,{
        headers:{
            'Authorization': userData === null ? "fg" : "Barear "+ userData.access_token,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'language': getLanguage(),
        }})
        .then(response => {
            return response.data;
        }).catch((error)=> {
            throw error;
        });
};
