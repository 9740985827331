import {DisclaimerComponent} from "../../components/Disclaimer/DisclaimerComponent";
import DownloadSection from "../../components/DownloadSection/Download";
import ContactSection from "../../components/ContactSection/Contact";
import FooterSection from "../../components/FooterSection/FooterTwo";
import React from "react";
import GreenStart from "../../components/InnerSection/GreenStart";
import {getAnalytics, logEvent} from "firebase/analytics";

export default function Disclaimer() {
    const analytics = getAnalytics();
    logEvent(analytics, "Disclaimer")
    return (
        <div>
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            {/*====== Scroll To Top Area End ======*/}
            <div className="main">
                <GreenStart></GreenStart>
                <DisclaimerComponent />
                <ContactSection />
                <FooterSection />
            </div>
        </div>
    );
}
