import {apiGet, apiPost, apiPut} from "./Api";


export function signupFull(data) {
    return apiPost('sign_up', data).then(success => {
        console.log(success)
        return success;
    }).catch(error => {
        throw error;
    })
}

export function signinCall(data) {
    return apiPost('login', data).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export function addVehicleCall(data) {
    return apiPost('add_vehicle', data).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}


export function getAllMyVehiclesCall() {
    return apiGet('get_all_my_vehicles').then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export function getAVehicleCall(code) {
    return apiGet('get_a_vehicle/'+code).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export function addInterestedPeopleCall(data) {
    return apiPost('add_interested_people', data).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}


export function checkInterestedUserCall(data) {
    return apiPut('check_interested_user', data).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export function getAllVehiclesCall() {
    return apiGet('get_all_vehicles/').then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export async function authCarCall(data) {
    return apiPost("check_car", data).then((result) => {
            return result;
        })
        .catch((err) => {
            throw err;
        });
}


export async function authInterestedUserCall(data) {
    return apiPost("check_interested_user", data).then((result) => {
        return result;
    })
        .catch((err) => {
            throw err;
        });
}

export function getPaymentLinkCall(code) {
    return apiGet('get_payment_link/'+code).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}


export function getPaymentStatusCall(code) {
    return apiGet('get_payment_status/'+code).then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export function getPricesCall() {
    return apiGet('get_prices').then(success => {
        return success;
    }).catch(error => {
        throw error;
    })
}

export async function checkCarExistCall(data) {
    return apiPost("check_car_exist", data).then((result) => {
        return result;
    })
        .catch((err) => {
            throw err;
        });
}
