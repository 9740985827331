import React, {useEffect, useState} from 'react';
import {hasAValue, priceFormatter} from "../uitls/SharedFunctions";
import {addVehicleCall, authCarCall, getPricesCall} from "../api/ApiCalls";
import {appErrorMessages, toastOptions} from "../config/constants";
import {toast} from "react-toastify";
import {Animated} from "react-animated-css";
import {stringsTranslate} from "../locales/CustomTranslater";
import {words} from "../locales/Words";
import {getAnalytics, logEvent} from "firebase/analytics";
import Swal from "sweetalert";


export default function StickerApplication() {
    const initData = {
        content: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum.",
        formHeading: "Your QR Application form",
        "cost": stringsTranslate(words.words.cost),
        "order": stringsTranslate(words.words.order),
        "orderInfo": stringsTranslate(words.words.orderInfo),
        "qr_code_sticker_of": stringsTranslate(words.words.qr_code_sticker_of),
        qr_code_sticker: stringsTranslate(words.words.qr_code_sticker),
        delivery_cost: stringsTranslate(words.words.delivery_cost),
        tax: stringsTranslate(words.words.tax),
        total: stringsTranslate(words.words.total),


        // formContent: "Fill all fields so we can get some info about you. We'll never send you spam",
        formText: "Don't have an account?",
        btnText: "Reqeuest QR stiker",
    }

    const [email, setEmail] = useState(undefined)
    const [address, setAddress] = useState(undefined)
    const [postalCode, setPostalCode] = useState(undefined)
    const [city, setCity] = useState(undefined)
    const [linkToWebSite, setLinkToWebSite] = useState(undefined)
    const [licensePlate, setLicensePlate] = useState(undefined)
    const [code, setCode] = useState(undefined);
    const [price, setPrice] = useState(undefined);


    const [lockEmail, setLockEmail] = useState(false);

    useEffect(() => {
           getPrices()
    }, [])


    const analytics = getAnalytics();
    logEvent(analytics, "Sticker application")

    return (
        <div className="bg-overlay min-vh-100">
            <section className="section discover-area overflow-hidden ptb_150">
                <div className="container">
                    <div className="row justify-content-center align-items-center mt-5 pt-3">
                        <div className="col-sm-12 col-md-12">
                            {/* Contact Box */}
                            <div className="contact-box text-center card p-4 shadow">
                                {/* Contact Form */}
                                {!lockEmail &&
                                <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                                    <div id="contact-form">
                                        <div className="contact-top">

                                            <h3 className="contact-title text-primary text-left">1-{initData.orderInfo} </h3>
                                            <h5 className="text-secondary-alt fw-4 py-3">{initData.formContent}</h5>
                                        </div>

                                        <div className="row">

                                            <div className={"col-12 contact-box card p-2 mb-3"}>
                                                <div className="row">
                                                    <div className="col-lg-6 col-md-12 col-sm-11 ">
                                                        <div
                                                            className="input-group d-flex align-items-center justify-content-center">
                                                            <img src="/img/logo/qr-website.png" alt="" width="150"
                                                                 height="55"/>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className="col-lg-6 col-md-12 col-sm-11 d-flex align-items-center mt-3">
                                                        <ul className="list-group w-100">
                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                <h3 className={"text-primary"}>{initData.qr_code_sticker} </h3>
                                                                <span className="badge">{hasAValue(price) &&
                                                                <h3 className={"text-primary"}>{price.subtotalPrice} </h3>}</span>
                                                            </li>

                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                <h3 className={"text-primary"}>{initData.delivery_cost} </h3>
                                                                <span className="badge">{hasAValue(price) &&
                                                                <h3 className={"text-primary"}>{price.deliveryCost} </h3>}</span>
                                                            </li>

                                                            <li className="list-group-item d-flex justify-content-between align-items-center">
                                                                <h3 className={"text-primary"}>{initData.total} </h3>
                                                                <span className="badge">{hasAValue(price) &&
                                                                <h3 className={"text-primary"}>{price.totalPrice} </h3>}</span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                </div>
                                            </div>


                                            <h3 className="contact-title text-primary col-12 mb-3 text-left py-3">2- {stringsTranslate(words.words.fill_in_the_info_of_your_car)}</h3>

                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*license Plate*/}
                                                <div className="input-group mb-3">

                                                    <input type="text"
                                                           className="form-control fw-5 license-plate-input"
                                                           name="kenteken"
                                                           placeholder={stringsTranslate(words.words.license_plate)}
                                                           required="required"
                                                           maxLength={6}
                                                           onChange={(val) => setLicensePlate(val.target.value.toString().toUpperCase())}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-car"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*Link*/}
                                                <div className="input-group mb-3">
                                                    <input type="url" className="form-control fw-5"
                                                           name="url"
                                                           placeholder={stringsTranslate(words.words.link_to_the_website)}
                                                           required="required"
                                                           maxLength={400}
                                                           onChange={(val) => setLinkToWebSite(val.target.value)}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-link"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className="contact-title text-primary col-12 mb-3 text-left py-3">3- {stringsTranslate(words.words.fill_in_your_personal_info)}</h3>

                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*Email*/}
                                                <div className="input-group mb-3">

                                                    <input type="email" className="form-control fw-5"
                                                           name="email"
                                                           placeholder={stringsTranslate(words.words.email)}
                                                           required="required"
                                                           maxLength={45}
                                                           onChange={(val) => setEmail(val.target.value)}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-envelope-open"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*Address*/}
                                                <div className="input-group mb-3">

                                                    <input type="text" className="form-control fw-5"
                                                           name="address"
                                                           placeholder={stringsTranslate(words.words.address)}
                                                           required="required" maxLength={40}
                                                           onChange={(val) => setAddress(val.target.value)}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-location-arrow"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*Postal code*/}
                                                <div className="input-group mb-3">

                                                    <input type="text" className="form-control fw-5"
                                                           name="postalCode"
                                                           placeholder={stringsTranslate(words.words.postal_code)}
                                                           required="required"
                                                           maxLength={6}
                                                           onChange={(val) => setPostalCode(val.target.value.toString().toUpperCase())}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-map-marked"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 col-sm-11">
                                                {/*City*/}
                                                <div className="input-group mb-3">

                                                    <input type="text" className="form-control fw-5"
                                                           name="city"
                                                           placeholder={stringsTranslate(words.words.city)}
                                                           required="required" maxLength={25}
                                                           onChange={(val) => setCity(val.target.value)}
                                                    />
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-city"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12 flex">

                                                <div className={"row d-flex justify-content-around"}>
                                                    <div className="">


                                                        <button className="btn btn-bordered w-100 mt-3 mt-sm-4"
                                                                onClick={() => {
                                                                    addCar()
                                                                }}
                                                        >{stringsTranslate(words.words.request_your_sticker)}</button>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Animated>}

                                {lockEmail &&
                                <Animated animationIn="bounceInLeft" animationOut="fadeOut" isVisible={true}>
                                    <div id="contact-form">
                                        <div className="contact-top">
                                            <h3 className="contact-title text-primary">3- {stringsTranslate(words.words.fill_in_the_code_that_you_received)}</h3>
                                            <h5 className="text-secondary-alt fw-4 py-3">{initData.formContent}</h5>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-sm-12">
                                                {/*Code*/}
                                                <div className="input-group mb-3">
                                                    <div className="input-group-prepend">
                                                        <button className="btn btn-outline-secondary"
                                                                type="button"><i className="fas fa-code"/>
                                                        </button>
                                                    </div>
                                                    <input type="text" name={"code"} className="form-control fw-5"
                                                           value={code} required="required" maxLength={25}
                                                           placeholder="Code"
                                                           onKeyPress={(val) => {
                                                               if (val.key === "Enter") {
                                                                   auth()
                                                               }
                                                           }}
                                                           onChange={(e) =>
                                                               setCode(e.target.value)
                                                           }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-sm-12">
                                                <button className="btn btn-bordered w-100 "
                                                        onClick={() => {
                                                            auth()
                                                        }}
                                                >{"Check code"}</button>
                                            </div>
                                        </div>
                                    </div>
                                </Animated>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

    function addCar() {
        let url = linkToWebSite
        let postal_code = postalCode
        let license_plate = licensePlate
        let data = {license_plate, address, url, email, postal_code, city}

        if (hasAValue(licensePlate) && hasAValue(address) && hasAValue(url) && hasAValue(email) && hasAValue(postal_code) && hasAValue(city)) {
            if (!validURL(url)) {
                toast.error(stringsTranslate(words.words.url_is_not_valid), toastOptions)
            } else {

                Swal({
                    title: stringsTranslate(words.words.place_an_order),
                    text: stringsTranslate(words.words.sure_from_all_info),
                    icon: "success",
                    buttons: true,
                }).then((response) => {
                    if (response) {

                        addVehicleCall(data).then(results => {
                            if (results.success === false) {
                                toast.error(results.message, toastOptions)
                            } else {
                                setLockEmail(true)
                                toast.success(results.message, toastOptions)
                            }
                        }).catch(error => {
                            if (hasAValue(error.response)) {
                                toast.error(error.response.data.error, toastOptions)
                            }
                        })
                    } else {
                        toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions)
                    }
                })
            }

        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions)

        }
    }

    function auth() {
        let data = {email, code}
        if (hasAValue(code)) {
            authCarCall(data).then(success => {
                console.log(success)
                toast.success(success.message, toastOptions);
                setTimeout(() => {
                    window.location = "/sticker-order-page/" + success.car_code
                }, 3000)
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions);
        }
    }


    function getPrices() {
        getPricesCall().then(success => {
            setPrice(success)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function validURL(str) {
        let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }


}
