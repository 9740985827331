import React, {useEffect, useState} from 'react';
import FooterSection from './../components/FooterSection/FooterTwo';
import FeatureVehicleSection from "../components/Features/FeatureVehicleSection";
import ScrollToTopComponent from "../components/ScrollToTopComponent";
import {getAVehicleCall} from "../api/ApiCalls";
import {hasAValue} from "../uitls/SharedFunctions";
import Breadcrumb from "../components/Breadcrumb";
import {getAnalytics, logEvent} from "firebase/analytics";
import {toast} from "react-toastify";
import {toastOptions} from "../config/constants";

export default function VehicleInfo({match}) {
    const [vehicleData , setVehicleData] = useState(undefined)

    useEffect(() => {
        getAVehicle(match.params.code)
    }, [0])
    const analytics = getAnalytics();
    logEvent(analytics, "Vehicle info")

    return (
        <div className="blog">
            <ScrollToTopComponent/>
            <div className="main">
                <Breadcrumb greetingText={false} title={hasAValue(vehicleData) && vehicleData.merk} subTitle={hasAValue(vehicleData) && vehicleData.handelsbenaming} city={hasAValue(vehicleData) && vehicleData.city}/>
                <FeatureVehicleSection vehicleData={vehicleData} />
                {/*<ContactSection/>*/}
                <FooterSection/>
            </div>
        </div>
    );

    function getAVehicle(code) {
        getAVehicleCall(code).then(result => {
            if(result.length !== 0){
                setVehicleData(result[0])
            }
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions)

        })
    }
}


