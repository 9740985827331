
export function saveDataLocalStorage(key, data){
    localStorage.setItem(key, JSON.stringify(data))
}

export function getDataLocalStorage(key){
    return JSON.parse(localStorage.getItem(key))
}

export function removeDataLocalStorage(key){
   localStorage.removeItem(key)
}

export function meregeDataLocalStorage(key, element, value){
    let data = JSON.parse(localStorage.getItem(key))
    data[element] = value
    localStorage.setItem(key, JSON.stringify(data))
}