export const words = {
    words: {
        language: "language",
        english: "english",
        arabic: "arabic",
        dutch: "dutch",
        vehicles: "vehicles",
        fast_sell: "fast_sell",
        car_info_overview: "car_info_overview",
        easy_for_everyone: "easy_for_everyone",
        request_your_sticker: "request_your_sticker",
        let_everyone_know: "let_everyone_know",
        faster_deal: "faster_deal",
        extra_views: "extra_views",
        unique_qr_code: "unique_qr_code",
        price: "price",
        orderInfo: "orderInfo",
        qr_code_sticker_of: "qr_code_sticker_of",
        qr_code_sticker: "qr_code_sticker",
        delivery_cost: "delivery_cost",
        tax: "tax",
        total: "total",
        cost: "cost",
        order: "order",
        features: "features",
        contact: "contact",
        fill_in_the_info_of_your_car: "fill_in_the_info_of_your_car",
        fill_in_your_personal_info: "fill_in_your_personal_info",
        fill_in_the_code_that_you_received: "fill_in_the_code_that_you_received",
        license_plate: "license_plate",
        email: "email",
        postal_code: "postal_code",
        link_to_the_website: "link_to_the_website",
        address: "address",
        city: "city",
        check_code: "check_code",
        thank_you: "thank_you",
        continue_to_pay: "continue_to_pay",
        go_to_home: "go_to_home",
        redirected_after3: "redirected_after3",
        im_interested: "im_interested",
        type: "type",
        color: "color",
        seats: "seats",
        technical_info: "technical_info",
        construction_year: "construction_year",
        cilinders: "cilinders",
        mass: "mass",
        economy_rating: "economy_rating",
        link_to_the_car: "link_to_the_car",
        let_the_owner_know: "let_the_owner_know",
        send: "send",
        name: "name",
        tel_number: "tel_number",
        engine_capacity: "engine_capacity",
        brand: "brand",
        model: "model",
        contact_subtitle: "contact_subtitle",
        example_title: "example_title",
        all_fields_are_required: "all_fields_are_required",
        url_is_not_valid: "url_is_not_valid",
        sure_from_all_info: "sure_from_all_info",
        place_an_order: "place_an_order",
        order_need_to_be_confirmed: "order_need_to_be_confirmed",
        you_will_receive_another_email_with_information_about_your_order: "you_will_receive_another_email_with_information_about_your_order",
        check_your_car: "check_your_car",
        go_to_your_car_page: "go_to_your_car_page",



        words: "words"
    },
    nl: {
        language: "Taal",
        english: "Engels",
        arabic: "Arabisch",
        dutch: "Nederlands",
        unique_qr_code: "Deze unieke qr-code kunt u op uw auto plakken",
        price: "Voor Slechts ",
        cost: "Nog Te Betalen",
        order: "QR-Code Sticker X 1",
        orderInfo: "Bestelling Overzicht",
        vehicles: "Voertuigen",
        fast_sell: "70% Sneller verkopen",
        car_info_overview: "All info op een plek",
        faster_deal: "80% Sneller een deal sluiten",
        easy_for_everyone: "Makkelijk voor iedereen",
        request_your_sticker: "Vraag uw sticker aan",
        let_everyone_know: "Met deze unieke sticker weet iedereen dat uw auto te koop staat.",
        features: "Features",
        contact: "Contact",
        fill_in_the_info_of_your_car: "Vul de gegevens van uw auto in",
        license_plate: "Uw Kenteken",
        email: "E-mailadres",
        postal_code: "Postcode",
        link_to_the_website: "Link naar de verkoopadvertentie (Marktplaats of facebook)",
        address: "Straatnaam + huisnummer",
        city: "Stad",
        fill_in_the_code_that_you_received: "Wij hebben een verificatiecode naar uw e-mailadres gestuurd. Deze kunt u hieronder invullen",
        check_code: "Code controleren",
        thank_you: "Dankuwel",
        continue_to_pay: "Doorgaan met betalen",
        go_to_home: "Ga naar Home",
        redirected_after3: "U wordt binnen enkele seconden automatisch doorgestuurd.",
        im_interested: "Ik ben geïnteresseerd",
        type: "Sort",
        color: "Kleur",
        seats: "Stoelen",
        technical_info: "Technisch informatie",
        construction_year: "Bouwjaar",
        cilinders: "Cilinders",
        mass: "Massa",
        economy_rating: "Economy classificatie",
        link_to_the_car: "Link naar de verkoop advertentie",
        let_the_owner_know: "Laat de eigenaar weten",
        send: "Versturen",
        name: "Naam",
        tel_number: "Mobile nummer",
        engine_capacity: "Motorvermogen",
        brand: "Merk",
        model: "Model",
        qr_code_sticker_of: "QR code sticker + bezorging voor",
        contact_subtitle: "Heeft u vragen over uw bestelling of over onze diensten. Neem gerust contact op via de contactpagina onderin de website",
        example_title: "Iedereen die de QR-Code scant krijgt direct alle informatie over de auto te zien en een link naar de verkoop advertentie.",
        extra_views: "Gemiddeld 150 extra views per dag",
        fill_in_your_personal_info: "Vul uw persoonlijke gegevens in",
        qr_code_sticker: "QR code sticker",
        delivery_cost: "Bezorgkosten",
        tax: "BTW",
        total: "Totaal",
        all_fields_are_required: "Alle velden zijn verplicht",
        url_is_not_valid: "Link is niet geldig",
        sure_from_all_info: "Alle gegevens zijn correct?",
        place_an_order: "Een bestelling plaatsen",
        order_need_to_be_confirmed: "Bestelling moet worden bevestigd",
        you_will_receive_another_email_with_information_about_your_order: "U ontvangt nog een e-mail met informatie over uw bestelling.",
        go_to_your_car_page: "Ga naar uw autopagina",

        words: "wordsNL"

    },

    en: {
        language: "Language",
        english: "English",
        arabic: "Arabic",
        dutch: "Dutch",
        vehicles: "Vehicles",
        fast_sell: "Sell 70% faster",
        car_info_overview: "Car overview",
        easy_for_everyone: "Easy for everyone",
        request_your_sticker: "Request your sticker",
        faster_deal: "80% faster making a deal",
        let_everyone_know: "With this unique sticker, everyone knows your car is for sale.",
        unique_qr_code: "Unique Qr-code especially for you",
        price: "For only",
        order: "QR-Code Sticker X 1",
        orderInfo:"Order Summary",
        features: "Features",
        contact: "Contact",
        fill_in_the_info_of_your_car: "Fill in the information of your car",
        license_plate: "License Plate",
        email: "Email",
        postal_code: "Postal Code",
        link_to_the_website: "Link to the website (Marktplaats or facebook)",
        address: "Street + house number",
        city: "City",
        fill_in_the_code_that_you_received: "We have sent you an email. Fill in the code that you have received",
        check_code: "Check code",
        thank_you: "Thank you",
        continue_to_pay: "Continue to pay",
        go_to_home: "Go to Home",
        redirected_after3: "You will be redirected after 3 sec",
        im_interested: "I'm Interested",
        type: "Type",
        cost: "To Pay ",
        color: "Color",
        seats: "Seats",
        technical_info: "Technical info",
        construction_year: "Construction year",
        cilinders: "Cylinders",
        mass: "Mass",
        economy_rating: "Economy rating",
        link_to_the_car: "Link to the advertisement",
        let_the_owner_know: "Let the owner know",
        send: "Send",
        name: "Name",
        tel_number: "Mobile number",
        engine_capacity: "Engine capacity",
        brand: "Brand",
        model: "Model",
        qr_code_sticker_of: "QR code sticker + delivery for ",
        contact_subtitle: "Questions about your order or about our service. Let us know",
        example_title: "Anyone who scans the code will see this info about your car",
        extra_views: "An average of 150 extra views every day",
        fill_in_your_personal_info: "Fill in your personal information",
        qr_code_sticker: "QR code sticker",
        delivery_cost: "Delivery cost",
        tax: "VAT",
        total: "Total",
        all_fields_are_required: "All fields are required",
        url_is_not_valid: "Url is not valid",
        sure_from_all_info: "Are sure from all info?",
        place_an_order: "Place an order",
        order_need_to_be_confirmed: "Order need to be confirmed",
        you_will_receive_another_email_with_information_about_your_order: "You will receive another email with information about your order.",
        go_to_your_car_page: "Go to your car page",


        words: "wordsEN"
    },
}
