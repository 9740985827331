import React, { Component } from 'react';
import {hasAValue} from "../uitls/SharedFunctions";

export default function Breadcrumb({title,subTitle, greetingText, city}) {

        return (
            <section className="section breadcrumb-area bg-overlay d-flex align-items-center">
                <div className="container pt-5 mt-5">
                    <div className="row ">
                        <div className="col-12">
                            {/* Breamcrumb Content */}
                            <div className="breadcrumb-content d-flex flex-column align-items-center text-center">
                                <h3 className="text-white">{greetingText !== false && "Hey"} {title} {subTitle} {hasAValue(city) && (" - ")} {hasAValue(city) && (city)} </h3>
                                <h3 className="text-white">{greetingText !== false && "Hey"} </h3>
                                {/*<ol className="breadcrumb justify-content-center">*/}
                                {/*    <li className="breadcrumb-item"><a className="text-white" >Home</a></li>*/}
                                {/*    <li className="breadcrumb-item"><a className="text-white">DashboardContent Pages</a></li>*/}
                                {/*    <li className="breadcrumb-item active text-white">{title}</li>*/}
                                {/*</ol>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
