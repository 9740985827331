import React, {useEffect, useState} from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {appLanguage, appLocalStoargeValues} from "../../config/constants";
import {getDataLocalStorage, removeDataLocalStorage} from "../../uitls/LocalStorageFunctions";
import {hasAValue} from "../../uitls/SharedFunctions";

export default function Header({imageData, switchLangauge}) {

    const [userData, setUserData] = useState({name: "friend"})

    useEffect(()=>{
        let data = getDataLocalStorage(appLocalStoargeValues.userData)
        setUserData(data)
    },[0])


    return (
        <header className="navbar navbar-expand-lg navbar-layout navbar-dark navbar-sticky">
            <div className="container position-relative">
                <a className="navbar-brand" href="/">
                    <img className="navbar-brand-regular" src={imageData} alt="brand-logo" height={60} width={200}/>
                    <img className="navbar-brand-sticky" src={"/img/logo/logoNoBackground.png"} alt="brand-logo" height={60} width={200}/>
                </a>
                <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="navbar-inner">
                    {/*  Mobile Menu Toggler */}
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <nav>
                        <ul className="navbar-nav" id="navbar-nav">

                            <li className="nav-item">
                                <a className="nav-link" href="/sticker-application"><strong>{stringsTranslate(words.words.request_your_sticker)}</strong></a>
                            </li>

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <strong> {stringsTranslate(words.words.language)}</strong>
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li>
                                        <a className="dropdown-item"  onClick={()=> {switchLangauge(appLanguage.nl)}}>  <strong>{stringsTranslate(words.words.dutch)}</strong></a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item"  onClick={()=> {switchLangauge(appLanguage.en)}}> <strong> {stringsTranslate(words.words.english)}</strong></a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );

    function logout(){
        removeDataLocalStorage(appLocalStoargeValues.userData)
        setUserData(undefined)
    }
}
