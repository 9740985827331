import React  from 'react';



export default function GreenStart(){
        return (
            <section id="home" className="section inner-area bg-overlay green-header overflow-hidden">
            </section>
        );
    }


