import React, { Component } from 'react';


export function DisclaimerComponent() {
    const initData = {
        "heading": "DISCLAIMER",
        "headingTwo": "Have questions? Look here",
        "headingText": "Op het gebruik van deze website (ww.it-plus24.com) zijn onderstaande gebruiksvoorwaarden van toepassing. Door gebruik\n" +
            "te maken van deze website, wordt u geacht kennis te hebben genomen van de gebruiksvoorwaarden en deze te hebben\n" +
            "aanvaard.\n",

        "headingTexttwo": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
        "faqText": "Haven't find suitable answer?",
        "faqTextLink": "Tell us what you need.",
        "faqData": [
            {
                "id": 1,
                "title": "Gebruik van informatie",
                "content": "It-plus24 B.V. streeft ernaar op deze website altijd juiste en actuele informatie aan te bieden. Hoewel deze informatie met de\n" +
                    "grootst mogelijke zorgvuldigheid is samengesteld, staat It-plus24 B.V. niet in voor de volledigheid, juistheid of actualiteit\n" +
                    "van de informatie. De juridische informatie op de website is van algemene aard en kan niet worden beschouwd als een\n" +
                    "vervanging van juridisch advies.\n" +
                    "Aan de informatie kunnen geen rechten worden ontleend. It-plus24 B.V. aanvaardt geen aansprakelijkheid voor schade die\n" +
                    "voortvloeit uit het gebruik van de informatie of de website en evenmin voor het niet goed functioneren van de website.\n" +
                    "Op basis van het verzenden en ontvangen van informatie via de website of via e-mail kan niet zonder meer een relatie tussen\n" +
                    "It-plus24 B.V. en de gebruiker van de website ontstaan.\n"
            },
            {
                "id": 2,
                "title": "E-mail",
                "content": "It-plus24 B.V. garandeert niet dat e-mails die aan It-plus24 B.V. worden verzonden (tijdig) worden ontvangen of verwerkt,\n" +
                    "omdat tijdige ontvangst van e-mails niet kan worden gegarandeerd. Ook de veiligheid van het e-mailverkeer kan niet\n" +
                    "volledig worden gegarandeerd door de hieraan verbonden veiligheidsrisico’s. Door zonder encryptie of\n" +
                    "wachtwoordbeveiliging per e-mail met It-plus24 B.V. te corresponderen, accepteert u dit risico.\n"
            },
            {
                "id": 3,
                "title": "Hyperlinks",
                "content": "Deze website kan hyperlinks bevatten naar websites van derden. It-plus24 B.V. heeft geen invloed op websites van derden\n" +
                    "en is niet verantwoordelijk voor de beschikbaarheid of inhoud daarvan. It-plus24 B.V. aanvaardt dan ook geen\n" +
                    "aansprakelijkheid voor schade die voortvloeit uit het gebruik van websites van derden.\n"
            },
            {
                "id": 4,
                "title": "Intellectuele eigendomsrechten",
                "content": "Alle publicaties en uitingen van It-plus24 B.V. zijn beschermd door auteursrecht en andere intellectuele eigendomsrechten.\n" +
                    "Behalve voor persoonlijk en niet-commercieel gebruik, mag niets uit deze publicaties en uitingen op welke manier dan ook\n" +
                    "verveelvoudigd, gekopieerd of op een andere manier openbaar worden gemaakt, zonder dat It-plus24 B.V. daar vooraf\n" +
                    "schriftelijke toestemming voor heeft gegeven.\n"
            }
        ]
    }

        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            {/* Single Card */}
                                            {initData.faqData.map((item, idx) => {
                                                return(
                                                    <div key={`fo_${idx}`} className="card border-0">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="mb-0">
                                                                <button className="btn px-0 py-2" type="button">
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        {/* Card Body */}
                                                        <div className="card-body px-0 py-3">
                                                            {item.content}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{initData.faqText} <a href="/#">{initData.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

