import React, { Component } from 'react';


export function TermsAndConditionsComponent() {
    const initData = {
        "heading": "ALGEMENE VOORWAARDEN",
        "headingTwo": "Have questions? Look here",
        "headingText": "LesOpTijd respects the privacy of its website visitors,\n" +
            "                                    in particular their rights regarding the automatic processing of personal data.\n" +
            "                                    We have therefore formulated and implemented a policy on complete transparency\n" +
            "                                    with our customers regarding the processing of personal data, its purpose(s)\n" +
            "                                    and the possibilities to exercise your legal rights in the best possible way.\n" +
            "If you require any additional information about the protection of personal data,\n" +
            "                                    please visit the website of the Dutch Data Protection Authority\n" +
            "                                    (Autoriteit Persoonsgegevens): https://autoriteitpersoonsgegevens.nl/nl \n" +
            "Until you accept the use of cookies and other tracking devices, we will not place\n" +
            "                                    any non-anonymised analytical cookies and / or tracking cookies on your computer,\n" +
            "                                    mobile phone or tablet.\n" +
            "                                    With the continued visit of this website you accept these terms of use and you\n" +
            "                                    accept the\n" +
            "                                    use of cookies and other tracking systems, unless we have provided for another\n" +
            "                                    method of\n" +
            "                                    accepting cookies on our website. \n" +
            "The current available version of this privacy policy is the only version that applies\n" +
            "                                    while visiting our website until a new version replaces the current version.",

        "headingTexttwo": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
        "faqText": "Haven't find suitable answer?",
        "faqTextLink": "Tell us what you need.",
        "faqData": [
            {
                "id": 1,
                "title": "Article 1 - Definities",
                "content": "1. It-plus24 B.V., gevestigd te Amsterdam, KvK-nummer 88227049, wordt in deze algemene voorwaarden aangeduid\n" +
                    "als verkoper.\n" +
                    "2. De wederpartij van verkoper wordt in deze algemene voorwaarden aangeduid als koper.\n" +
                    "3. Partijen zijn verkoper en koper samen.\n" +
                    "4. Met de overeenkomst wordt bedoeld de koopovereenkomst tussen partijen.\n"
            },
            {
                "id": 2,
                "title": "Article 2 - Toepasselijkheid algemene voorwaarden",
                "content": "1. Deze voorwaarden zijn van toepassing op alle offertes, aanbiedingen, overeenkomsten en leveringen van diensten of\n" +
                    "goederen door of namens verkoper.\n" +
                    "2. Afwijken van deze voorwaarden kan alleen als dat uitdrukkelijk én schriftelijk door partijen is overeengekomen.\n"
            },
            {
                "id": 3,
                "title": "Article 3 - Betaling",
                "content": "1. De volledige koopsom wordt altijd meteen in de webshop voldaan. Bij reserveringen wordt in sommige gevallen een\n" +
                    "aanbetaling verwacht. In dat geval krijgt de koper een bewijs van de reservering en de vooruitbetaling.\n" +
                    "2. Betaalt koper niet op tijd, dan is hij in gebreke. Blijft koper in gebreke, dan is verkoper gerechtigd de verplichtingen\n" +
                    "op te schorten totdat koper aan zijn betalingsverplichting heeft voldaan.\n" +
                    "3. Blijft koper in gebreke, dan zal verkoper tot invordering overgaan. De kosten met betrekking tot die invordering\n" +
                    "komen voor rekening van de koper. Deze incassokosten worden berekend aan de hand van het Besluit vergoeding\n" +
                    "voor buitengerechtelijke incassokosten.\n" +
                    "4. In geval van liquidatie, faillissement, beslag of surseance van betaling van de koper zijn de vorderingen van verkoper\n" +
                    "op de koper onmiddellijk opeisbaar.\n" +
                    "5. Weigert koper zijn medewerking aan de uitvoering van de opdracht door verkoper, dan is hij nog steeds verplicht de\n" +
                    "afgesproken prijs aan verkoper te betalen.\n"
            },
            {
                "id": 4,
                "title": "Article 5 - Aanbiedingen, offertes en prijs",
                "content": "1. Aanbiedingen zijn vrijblijvend, tenzij in het aanbod een termijn van aanvaarding is genoemd. Wordt het aanbod niet\n" +
                    "binnen die gestelde termijn aanvaard, dan vervalt het aanbod.\n" +
                    "2. Levertijden in offertes zijn indicatief en geven koper bij overschrijding daarvan geen recht op ontbinding of\n" +
                    "schadevergoeding, tenzij partijen uitdrukkelijk én schriftelijk anders zijn overeengekomen.\n" +
                    "3. Aanbiedingen en offertes gelden niet automatisch voor nabestellingen. Partijen moeten dit uitdrukkelijk én\n" +
                    "schriftelijk overeenkomen.\n" +
                    "4. De op aanbiedingen, offertes en facturen genoemde prijs bestaat uit de koopprijs inclusief de verschuldigde btw en\n" +
                    "eventuele andere overheidsheffingen.\n"
            },
            {
                "id": 5,
                "title": "Article 4 - Herroepingsrecht",
                "content": "1. De consument krijgt het recht om na ontvangst van de bestelling binnen 14 dagen de overeenkomst zonder opgave\n" +
                    "van redenen te ontbinden (herroepingsrecht). De termijn begint te lopen vanaf het moment dat de (gehele) bestelling\n" +
                    "door de consument is ontvangen.\n" +
                    "2. Er bestaat geen herroepingsrecht wanneer de producten volgens zijn specificaties op maat zijn gemaakt of slechts\n" +
                    "kort houdbaar zijn.\n" +
                    "3. De consument kan een herroepingsformulier van verkoper gebruiken. Verkoper is gehouden dit terstond na de vraag\n" +
                    "van koper aan koper ter beschikking te stellen.\n" +
                    "4. Tijdens de bedenktijd zal de consument zorgvuldig omgaan met het product en de verpakking. Hij zal het product\n" +
                    "slechts in die mate uitpakken of gebruiken voor zover dat nodig is om te kunnen beoordelen of hij het product wenst\n" +
                    "\n" +
                    "  \n" +
                    " \n" +
                    "te behouden. Indien hij van zijn herroepingsrecht gebruik maakt, zal hij het ongebruikte en onbeschadigde product\n" +
                    "met alle geleverde toebehoren en - indien redelijkerwijze mogelijk - in de originele verzendverpakking aan verkoper\n" +
                    "retourneren, conform de door de ondernemer verstrekte redelijke en duidelijke instructies.\n"
            },
            {
                "id": 6,
                "title": "Article 6 - Wijziging van de overeenkomst",
                "content": "1. Indien tijdens de uitvoering van de overeenkomst blijkt dat het voor een behoorlijke uitvoering van de opdracht\n" +
                    "noodzakelijk is om de te verrichten werkzaamheden te wijzigen of aan te vullen, passen partijen tijdig en in onderling\n" +
                    "overleg de overeenkomst dienovereenkomstig aan.\n" +
                    "2. Indien partijen overeenkomen dat de overeenkomst wordt gewijzigd of aangevuld, kan het tijdstip van voltooiing van\n" +
                    "de uitvoering daardoor worden beïnvloed. Verkoper stelt koper hiervan zo spoedig mogelijk op de hoogte.\n" +
                    "3. Indien de wijziging van of aanvulling op de overeenkomst financiële en/of kwalitatieve consequenties heeft, licht\n" +
                    "verkoper koper hierover vooraf schriftelijk in.\n" +
                    "4. Indien partijen een vaste prijs zijn overeengekomen, geeft verkoper daarbij aan in hoeverre de wijziging of\n" +
                    "aanvulling van de overeenkomst een overschrijding van deze prijs tot gevolg heeft.\n" +
                    "5. In afwijking van het bepaalde in het derde lid van dit artikel kan verkoper geen meerkosten in rekening brengen\n" +
                    "indien de wijziging of aanvulling het gevolg is van omstandigheden die aan hem kunnen worden toegerekend.\n"
            },
            {
                "id": 7,
                "title": "Article 7 - Oplevering en risico-overgang",
                "content": "1. Zodra het gekochte door de koper in ontvangst is genomen, gaat het risico over van verkoper naar koper."
            },
            {
                "id": 8,
                "title": "Article 8 - Onderzoek en reclames",
                "content": "1. Koper is gehouden het geleverde op het moment van (af)levering, maar in ieder geval binnen zo kort mogelijke\n" +
                    "termijn te (doen) onderzoeken. Daarbij behoort koper te onderzoeken of kwaliteit en kwantiteit van het geleverde\n" +
                    "overeenstemmen met hetgeen partijen zijn overeengekomen, althans dat kwaliteit en kwantiteit voldoen aan de eisen\n" +
                    "die daaraan in het normale (handels)verkeer gelden.\n" +
                    "2. Reclames met betrekking tot beschadigingen, tekorten of verlies van geleverde goederen moeten binnen 10\n" +
                    "werkdagen na de dag van aflevering van de goederen door koper schriftelijk bij verkoper worden ingediend.\n" +
                    "3. Bij gegrondverklaring van de klacht binnen de gestelde termijn heeft verkoper het recht om óf te herstellen, óf\n" +
                    "opnieuw te leveren, óf om van levering af te zien en koper een creditnota te sturen voor dat deel van de koopprijs.\n" +
                    "4. Geringe en/of in de branche gebruikelijke afwijkingen en verschil in kwaliteit, aantal, maat of afwerking kunnen niet\n" +
                    "aan verkoper worden tegengeworpen.\n" +
                    "5. Klachten met betrekking tot een bepaald product hebben geen invloed op andere producten dan wel onderdelen\n" +
                    "behorende bij diezelfde overeenkomst.\n" +
                    "6. Na het verwerken van de goederen bij koper worden geen reclames meer geaccepteerd.\n"
            },
            {
                "id": 9,
                "title": "Article 9 - Monsters en modellen",
                "content": "1. Is aan koper een monster of model getoond of verstrekt, dan wordt het vermoed slechts als aanduiding te zijn\n" +
                    "verstrekt zonder dat de te leveren zaak daaraan behoeft te beantwoorden. Dit is anders als partijen uitdrukkelijk zijn\n" +
                    "overeengekomen dat de te leveren zaak wel daarmee zal overeenstemmen.\n" +
                    "2. Bij overeenkomsten ter zake van een onroerende zaak wordt vermelding van de oppervlakte of andere afmetingen en\n" +
                    "aanduidingen eveneens vermoed slechts als aanduiding bedoeld te zijn, zonder dat de te leveren zaak daaraan behoeft\n" +
                    "te beantwoorden.\n"
            },
            {
                "id": 10,
                "title": "Article 10 - Levering",
                "content": "1. Levering geschiedt ‘af fabriek/winkel/magazijn’. Dit houdt in dat alle kosten voor koper zijn.\n" +
                    "2. Koper is verplicht de zaken af te nemen op het moment dat verkoper deze bij hem aflevert of doet afleveren, dan wel\n" +
                    "op het moment waarop deze zaken hem volgens de overeenkomst ter beschikking worden gesteld.\n" +
                    "3. Indien koper afname weigert of nalatig is in het verstrekken van informatie of instructies die noodzakelijk zijn voor\n" +
                    "de levering, is verkoper gerechtigd de zaak voor rekening en risico van koper op te slaan.\n" +
                    "4. Indien de zaken worden bezorgd, is verkoper gerechtigd eventuele bezorgkosten in rekening te brengen.\n" +
                    "\n" +
                    "  \n" +
                    " \n" +
                    "5. Indien verkoper gegevens van koper nodig heeft voor de uitvoering van de overeenkomst, vangt de levertijd aan\n" +
                    "nadat koper deze gegevens aan verkoper ter beschikking heeft gesteld.\n" +
                    "6. Een door verkoper opgegeven termijn voor levering is indicatief. Dit is nooit een fatale termijn. Bij overschrijding\n" +
                    "van de termijn moet koper verkoper schriftelijk in gebreke stellen.\n" +
                    "7. Verkoper is gerechtigd de zaken in gedeelten te leveren, tenzij partijen dit anders schriftelijk zijn overeengekomen of\n" +
                    "aan deellevering geen zelfstandige waarde toekomt. Verkoper is bij levering in delen gerechtigd deze delen\n" +
                    "afzonderlijk te factureren.\n"
            },
            {
                "id": 11,
                "title": "Article 11 - Overmacht",
                "content": "1. Kan verkoper niet, niet tijdig of niet behoorlijk aan zijn verplichtingen uit de overeenkomst voldoen door overmacht,\n" +
                    "dan is hij niet aansprakelijk voor door koper geleden schade.\n" +
                    "2. Onder overmacht verstaan partijen in ieder geval iedere omstandigheid waarmee verkoper ten tijde van het aangaan\n" +
                    "van de overeenkomst geen rekening kon houden en ten gevolge waarvan de normale uitvoering van de overeenkomst\n" +
                    "redelijkerwijs niet door koper kan worden verlangd zoals bijvoorbeeld ziekte, oorlog of oorlogsgevaar, burgeroorlog\n" +
                    "en oproer, molest, sabotage, terrorisme, energiestoring, overstroming, aardbeving, brand, bedrijfsbezetting,\n" +
                    "werkstakingen, werkliedenuitsluiting, gewijzigde overheidsmaatregelen, transportmoeilijkheden, en andere storingen\n" +
                    "in het bedrijf van verkoper.\n" +
                    "3. Voorts verstaan partijen onder overmacht de omstandigheid dat toeleveringsbedrijven waarvan verkoper afhankelijk\n" +
                    "is voor de uitvoering van de overeenkomst, niet aan de contractuele verplichtingen jegens verkoper voldoen, tenzij\n" +
                    "zulks aan verkoper te verwijten is.\n" +
                    "4. Indien zich een situatie als hiervoor bedoeld voordoet als gevolg waarvan verkoper niet aan zijn verplichtingen\n" +
                    "jegens koper kan voldoen, dan worden die verplichtingen opgeschort zolang verkoper niet aan zijn verplichtingen\n" +
                    "kan voldoen. Indien de in de vorige zin bedoelde situatie 30 kalenderdagen heeft geduurd, hebben partijen het recht\n" +
                    "de overeenkomst schriftelijk geheel of gedeeltelijk te ontbinden.\n" +
                    "5. Ingeval de overmacht langer dan drie maanden voortduurt, heeft koper het recht de overeenkomst met onmiddellijke\n" +
                    "ingang te ontbinden. Ontbinding kan alleen via een aangetekende brief.\n"
            },
            {
                "id": 12,
                "title": "Article 12 - Overdracht van rechten",
                "content": "1. Rechten van een partij uit deze overeenkomst kunnen niet worden overgedragen zonder de voorafgaande schriftelijke\n" +
                    "instemming van de andere partij. Deze bepaling geldt als een beding met goederenrechtelijke werking zoals bedoeld\n" +
                    "in artikel 3:83, tweede lid, Burgerlijk Wetboek.\n"
            },
            {
                "id": 13,
                "title": "Article 13 - Eigendomsvoorbehoud en retentierecht",
                "content": "1. De bij verkoper aanwezige zaken en geleverde zaken en onderdelen blijven eigendom van verkoper totdat koper de\n" +
                    "gehele afgesproken prijs heeft betaald. Tot die tijd kan verkoper zich beroepen op zijn eigendomsvoorbehoud en de\n" +
                    "zaken terugnemen.\n" +
                    "2. Indien de overeengekomen vooruit te betalen bedragen niet of niet op tijd worden voldaan, heeft verkoper het recht\n" +
                    "om de werkzaamheden op te schorten totdat het overeengekomen deel alsnog is voldaan. Er is dan sprake van\n" +
                    "schuldeisersverzuim. Een verlate levering kan in dat geval niet aan verkoper worden tegengeworpen.\n" +
                    "3. Verkoper is niet bevoegd de onder zijn eigendomsvoorbehoud vallende zaken te verpanden noch op enige andere\n" +
                    "wijze te bezwaren.\n" +
                    "4. Verkoper verplicht zich de onder eigendomsvoorbehoud aan koper geleverde zaken te verzekeren en verzekerd te\n" +
                    "houden tegen brand, ontploffings- en waterschade alsmede tegen diefstal en de polis op eerste verzoek ter inzage te\n" +
                    "geven.\n" +
                    "5. Indien zaken nog niet zijn geleverd, maar de overeengekomen vooruitbetaling of prijs niet conform afspraak is\n" +
                    "voldaan, heeft verkoper het recht van retentie. De zaak wordt dan niet geleverd totdat koper volledig en conform\n" +
                    "afspraak heeft betaald.\n" +
                    "6. In geval van liquidatie, insolventie of surseance van betaling van koper zijn de verplichtingen van koper onmiddellijk\n" +
                    "opeisbaar.\n"
            },
            {
                "id": 14,
                "title": "Article 14 - Aansprakelijkheid",
                "content": "1. Iedere aansprakelijkheid voor schade, voortvloeiende uit of verband houdende met de uitvoering van een\n" +
                    "overeenkomst, is steeds beperkt tot het bedrag dat in het desbetreffende geval door de gesloten\n" +
                    "aansprakelijkheidsverzekering(en) wordt uitbetaald. Dit bedrag wordt vermeerderd met het bedrag van het eigen\n" +
                    "risico volgens de desbetreffende polis.\n" +
                    "2. Niet uitgesloten is de aansprakelijkheid van verkoper voor schade die het gevolg is van opzet of bewuste\n" +
                    "roekeloosheid van verkoper of zijn leidinggevende ondergeschikten.\n"
            },
            {
                "id": 15,
                "title": "Article 15 - Klachtplicht",
                "content": "1. Koper is verplicht klachten over de verrichte werkzaamheden direct te melden aan verkoper. De klacht bevat een zo\n" +
                    "gedetailleerd mogelijke omschrijving van de tekortkoming, zodat verkoper in staat is hierop adequaat te reageren.\n" +
                    "2. Is een klacht gegrond, dan is verkoper gehouden het goed te herstellen en eventueel te vervangen.\n"
            },
            {
                "id": 16,
                "title": "Article 16 - Garanties",
                "content": "1. Indien in de overeenkomst garanties zijn opgenomen, geldt het hiernavolgende. Verkoper garandeert dat het\n" +
                    "verkochte aan de overeenkomst beantwoordt, dat het zonder gebreken zal functioneren en dat het geschikt is voor het\n" +
                    "gebruik dat koper voornemens is ervan te maken. Deze garantie geldt voor een periode van twee kalenderjaren na\n" +
                    "ontvangst van het verkochte door koper.\n" +
                    "2. De bedoelde garantie strekt ertoe om tussen verkoper en koper een zodanige risicoverdeling tot stand te brengen dat\n" +
                    "de gevolgen van een inbreuk op een garantie steeds volledig voor rekening en risico van verkoper komen en dat\n" +
                    "verkoper zich ter zake een inbreuk op een garantie nooit kan beroepen op artikel 6:75 BW. Het bepaalde in de vorige\n" +
                    "zin geldt ook als de inbreuk bij koper bekend was of bekend had kunnen zijn door het verrichten van onderzoek.\n" +
                    "3. De genoemde garantie geldt niet wanneer het gebrek is ontstaan als gevolg van onoordeelkundig of oneigenlijk\n" +
                    "gebruik of wanneer - zonder toestemming - koper of derden wijzigingen hebben aangebracht dan wel geprobeerd\n" +
                    "hebben aan te brengen of het gekochte hebben gebruikt voor doeleinden waarvoor het niet bestemd is.\n" +
                    "4. Indien de door verkoper verstrekte garantie betrekking heeft op een door een derde geproduceerde zaak is de garantie\n" +
                    "beperkt tot de garantie die door die producent wordt verstrekt.\n"
            },
            {
                "id": 17,
                "title": "Article 17 - Intellectueel eigendom",
                "content": "1. It-plus24 B.V. behoudt alle intellectuele eigendomsrechten (waaronder auteursrecht, octrooirecht, merkenrecht,\n" +
                    "tekeningen- en modellen-recht, etc.) op alle producten, ontwerpen, tekeningen, geschriften, dragers met gegevens of\n" +
                    "andere informatie, offertes, afbeeldingen, schetsen, modellen, maquettes, etc., tenzij partijen schriftelijk anders zijn\n" +
                    "overeengekomen.\n" +
                    "2. De klant mag genoemde intellectuele eigendomsrechten niet zonder voorafgaande schriftelijke toestemming van It-\n" +
                    "plus24 B.V. (laten) kopiëren, aan derden tonen en/of ter beschikking stellen of op andere wijze gebruiken.\n"
            },
            {
                "id": 18,
                "title": "Article 18 - Wijziging algemene voorwaarden",
                "content": "1. It-plus24 B.V. is gerechtigd deze algemene voorwaarden te wijzigen of aan te vullen.\n" +
                    "2. Wijzigingen van ondergeschikt belang kunnen te allen tijde worden doorgevoerd.\n" +
                    "3. Grote inhoudelijke wijzigingen zal It-plus24 B.V. zoveel mogelijk vooraf met de klant bespreken.\n" +
                    "4. Consumenten zijn gerechtigd bij een wezenlijke wijziging van de algemene voorwaarden de overeenkomst op te\n" +
                    "zeggen.\n"
            },
            {
                "id": 19,
                "title": "Article 19 - Toepasselijk recht en bevoegde rechter",
                "content": "1. Op iedere overeenkomst tussen partijen is uitsluitend het Nederlands recht van toepassing.\n" +
                    "2. De Nederlandse rechter in het arrondissement waar It-plus24 B.V. is gevestigd is exclusief bevoegd om kennis te\n" +
                    "nemen van eventuele geschillen tussen partijen, tenzij de wet dwingend anders voorschrijft.\n" +
                    "3. De toepasselijkheid van het Weens Koopverdrag is uitgesloten.\n" +
                    "4. Wanneer in een gerechtelijke procedure één of meerdere bepalingen van deze algemene voorwaarden als onredelijk\n" +
                    "bezwarend worden aangemerkt, dan blijven de overige bepalingen onverminderd van kracht.\n"
            }
        ]
    }

        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            {/* Single Card */}
                                            {initData.faqData.map((item, idx) => {
                                                return(
                                                    <div key={`fo_${idx}`} className="card border-0">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="mb-0">
                                                                <button className="btn px-0 py-2" type="button">
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        {/* Card Body */}
                                                        <div className="card-body px-0 py-3">
                                                            {item.content}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{initData.faqText} <a href="/#">{initData.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

