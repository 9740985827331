import React, {useEffect, useState} from 'react';
import HeroSection from '../components/HeroSection/HeroThree';
import FeatureSection from '../components/Features/FeatureTwo';
import ServiceSection from '../components/ServiceSection/ServiceTwo';
import DiscoverSection from '../components/DiscoverSection/DiscoverTwo';
import FooterSection from '../components/FooterSection/FooterTwo';
import ContactSection from "../components/ContactSection/Contact";
import Download from "../components/DownloadSection/Download";
import {getPricesCall} from "../api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../config/constants";
import {getAnalytics, logEvent} from "firebase/analytics";
import Example from "../components/ServiceSection/Example";

export default function Home() {
    const [price, setPrice] = useState(undefined);

    useEffect(() => {
        getPrices()
    }, [])

    const analytics = getAnalytics();
    logEvent(analytics, "Home")

    return (
        <div className="homepage-4">
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up"/>
            </div>
            {/*====== Scroll To Top Area End ======*/}
            <div className="main">
                <HeroSection/>
                <FeatureSection/>
                {/*<DiscoverSection/>*/}
                <ServiceSection price={price}/>
                <Example price={price}/>
                {/*<Download/>*/}
                <ContactSection/>
                <FooterSection/>
            </div>
        </div>
    );

    function getPrices() {
        getPricesCall().then(success => {
            setPrice(success)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }
}
