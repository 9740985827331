import {stringsTranslate} from "../locales/CustomTranslater";
import {words} from "../locales/Words";

export const appColors = {
    primary: 'rgba(21, 128, 161, 0.96)',
    secondary: 'rgba(176, 183, 19, 0.96)',
    secondaryAlt: '#090808',
    border: '#000000',
    inactive: '#c7ced3',
    baseBackground: '#F6F6F7',
    darkBackground: 'rgba(2,56,3,0.4)',
    white: '#FFFFFF',
    light: '#A8B3BA',
    black: '#090808',
    green: '#32CD32',
    red: '#FF4500',
    transparent: 'rgba(1,214,132,0.2)',
    gray: '#505050',
}


export const appLocalStoargeValues = {
    userData: 'userData',
    loggedIn: 'loggedIn',
    language: 'language',
}

export const appUrls = {
    backend_api: 'https://api.qrvehicle.com/', //production
    // backend_api: 'http://localhost:3000/', //test
}

export const appRoutes = {
    home: '/',
    features: '/features',
    plans: '/plans',
    signup: '/sign-up',
    signin: '/sign-in',
    verification: '/verification',
    dashboard: '/dashboard',
    drivingInstructorFeatures: '/driving-instructor-features',
    dataIntegration: '/dataintegration',
}

export const appLanguage = {
    nl: 'nl',
    en: 'en',
    ar: 'ar',
}

export const QRCodeStylingOptions = {
    "width": 300,
    "height": 300,
    "margin": 0,
    "qrOptions": {"typeNumber": "5", "mode": "Byte", "errorCorrectionLevel": "L"},
    "imageOptions": {"hideBackgroundDots": true, "imageSize": 0.3, "margin": 8},
    "dotsOptions": {
        "type": "extra-rounded",
        "color": "#6a1a4c",
        "gradient": {
            "type": "linear",
            "rotation": -0.6108652381980153,
            "colorStops": [{"offset": 0, "color": "#b0b713"}, {"offset": 1, "color": "#1580a1"}]
        }
    },
    "backgroundOptions": {"color": "#ffffff", "gradient": null},
    "image": null,
    "dotsOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#6a1a4c", "color2": "#6a1a4c", "rotation": "0"}
    },
    "cornersSquareOptions": {"type": "extra-rounded", "color": "#000000"},
    "cornersSquareOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
    },
    "cornersDotOptions": {
        "type": "dot",
        "color": "#000000",
        "gradient": {
            "type": "linear",
            "rotation": 0,
            "colorStops": [{"offset": 0, "color": "#b0b713"}, {"offset": 1, "color": "#1580a1"}]
        }
    },
    "cornersDotOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#000000", "color2": "#000000", "rotation": "0"}
    },
    "backgroundOptionsHelper": {
        "colorType": {"single": true, "gradient": false},
        "gradient": {"linear": true, "radial": false, "color1": "#ffffff", "color2": "#ffffff", "rotation": "0"}
    }
};


export const toastOptions = {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    limit: 5,
};

export const appErrorMessages = {
    all_fields_are_required: stringsTranslate(words.words.all_fields_are_required),
    url_is_not_valid: stringsTranslate(words.words.url_is_not_valid),
};
