import i18n from 'i18n-js';
import {getLanguage} from "../uitls/SharedFunctions";
import {words} from "./Words";

i18n.translations = words

i18n.locale = getLanguage();
i18n.fallbacks = {languageTag: "nl", isRTL: false};

export function stringsTranslate(name) {
    return i18n.t(name);
}