import React, {useEffect} from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

// importing all the pages
import Home from "../pages/Home";
import ErrorPage from "../components/ErrorPage/404";
import Disclaimer from "../pages/Rules/Disclaimer";
import VehicleInfo from "../pages/VehicleInfo";
import StickerApplication from "../pages/StickerApplication";
import ThankYou from "../pages/ThankYou";
import StickerOrderPage from "../pages/StickerOrderPage";
import TermsAndConditions from "../pages/Rules/TermsAndConditions";
import PrivacyAndPolicy from "../pages/Rules/PrivacyAndPolicy";

export default function MyRouts() {

    return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Home}>
                        {/*{hasAValue(userData) && <Redirect to="/dashboard" />}*/}
                    </Route>
                    <Route path="/vehicle-info/:code" component={VehicleInfo}/>
                    {/*<Route path="/reviews" component={Reviews} />*/}
                    {/*<Route path="/pricing" component={Pricing} />*/}
                    {/*<Route path="/download-page" component={DownloadPage} />*/}
                    <Route path="/thank-you/:code" component={ThankYou} />
                    <Route path="/sticker-order-page/:code" component={StickerOrderPage} />
                    <Route path="/terms-conditions" component={TermsAndConditions}/>
                    <Route path="/privacy-policy" component={PrivacyAndPolicy}/>
                    <Route path="/disclaimer" component={Disclaimer}/>
                    <Route path="/sticker-application" component={StickerApplication}/>
                     {/*<Route path="/login" component={Login}>{hasAValue(userData) && <Redirect to="/" />}</Route>*/}
                     {/*<Route path="/signup" component={Signup}>{hasAValue(userData) && <Redirect to="/" />}</Route>*/}
                     {/*<Route path="/forgot" component={Forgot}>{hasAValue(userData) && <Redirect to="/" />}</Route>*/}
                    {/*<Route path="/faq" component={Faq} />*/}
                    <Route path="/error-page" component={ErrorPage}/>
                    {/*<Route path="/contact-page" component={ContactPage} />*/}
                    <Redirect to='/error-page'/>
                </Switch>
            </Router>
    );
}

