import {getDataLocalStorage, saveDataLocalStorage} from "./LocalStorageFunctions";
import {appLocalStoargeValues} from "../config/constants";

export function isLoggedIn(){
    let loggedIn = getDataLocalStorage(appLocalStoargeValues.loggedIn)
    if(loggedIn === null){
        saveDataLocalStorage(appLocalStoargeValues.loggedIn, false)
    }
    return getDataLocalStorage(appLocalStoargeValues.loggedIn)
}

export function isAccountVerified(){
    let userData = getDataLocalStorage(appLocalStoargeValues.userData)
    if(userData === null){
        return false;
    }else{
        return userData.profile_verified
    }
}

export function navigateTo(route){
    window.location = route
}

export function getLanguage(){
    let language = getDataLocalStorage(appLocalStoargeValues.language)
    if(language === null){
        return "nl";
    }else{
        return language
    }
}

export function hasAValue(value){
    if(value !== null && value !== undefined){
        return true
    }else{
        return false
    }
}


export function priceFormatter(value){
    if(hasAValue(value)){
        let priceLength = value.toString().length
        let priceOnePart = value.toString().substring(0, priceLength-2)
        let priceTwoPart = value.toString().substring(priceLength-2, priceLength)
        return "€ "+priceOnePart+","+priceTwoPart
    }else{
        return ""

    }

}
