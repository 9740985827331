import React, { Component } from 'react';

class FooterSection extends Component {
    state = {
        footerList_4: [
            {
                "id": 1,
                "text": "Disclaimer",
                "link": "/disclaimer"
            },
            {
                "id": 2,
                "text": "Terms & Conditions",
                "link": "/terms-conditions"
            },
            {
                "id": 3,
                "text": "Privacy & Policy",
                "link": "/privacy-policy"
            },
        ]
    }


    render() {
        return (
            <footer className="section inner-footer bg-gray ptb_50">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-6">
                        {/* Footer Items */}
                        <div className="footer-items text-center">
                        {/* Logo */}
                        <a className="navbar-brand mb-3" href="/#">
                            <img className="logo" src={"/img/logo/logoNoBackground.png"}  width={300}  alt="" />
                        </a>
                        {/*<p className="mt-2 mb-3">{this.state.data.text}</p>*/}
                        <ul className="d-flex flex-column flex-sm-row justify-content-center">
                            {this.state.footerList_4.map((item, idx) => {
                                return(
                                    <li key={`fl_${idx}`} className="py-1 px-2 fw-7"><a href={item.link}>{item.text}</a></li>
                                );
                            })}
                        </ul>
                        {/* Copyright Area */}
                        <div className="copyright-area border-0 pt-3">
                            {/*<p>Made with <i className="fas fa-heart" /> By <a href="/#">TagDev</a></p>*/}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default FooterSection;
