import React  from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {
    BsAppIndicator,
    BsGlobe, FaRegHandPointRight, FaUsersCog, GiShakingHands,
    IoCalendarOutline,
    MdOutlineFeedback, TbHandFinger
} from "react-icons/all";
import {Link} from "react-router-dom";
import {hasAValue, priceFormatter} from "../../uitls/SharedFunctions";
import {appColors} from "../../config/constants";
import {Animated} from "react-animated-css";


export default function ServiceSection({price}) {
    const initData = {
        "heading": stringsTranslate(words.words.unique_qr_code),
        "pricing": stringsTranslate(words.words.price),
        "buttonText": stringsTranslate(words.words.request_your_sticker),
        "thumbTwo": "/img/extra/IMG_5827.png",
        "thumbTwo1": "/img/extra/IMG_5815.png",

    }

        return (
            <section id={"service"} className="section service-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-content-center align-items-center">
                    <div className="col-12 col-md-6">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">

                            <h2 className="mb-4">{initData.heading} <FaRegHandPointRight color={appColors.secondary} size={50}/></h2>
                            {hasAValue(price) && <h3 className="text-danger text-opacity-25">{initData.pricing} {price.subtotalPrice}<a className={"h5"}></a></h3>}

                        <Link to="/sticker-application" className="btn btn-bordered mt-4 ">{initData.buttonText}</Link>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        {/* Service Thumb */}
                        <Animated className="" animationInDelay={250} animationIn="jackInTheBox" isVisible={true}>
                            <img className={"border-radius-img car-img"} src={initData.thumbTwo1} alt="" />
                        </Animated>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
