import React, {Component, useEffect, useState} from 'react';
import {getPaymentStatusCall} from "../api/ApiCalls";
import {stringsTranslate} from "../locales/CustomTranslater";
import {words} from "../locales/Words";
import {getAnalytics, logEvent} from "firebase/analytics";


export default function ThankYou({match}) {
    const [paymentLink, setPaymentLink] = useState(undefined)

    // useEffect(() => {
    //     getStatusOfOrder(match.params.code)
    // }, [])
    const analytics = getAnalytics();
    logEvent(analytics, "ThankYou page")

    return (
        <div className="inner inner-pages">
            <div className="main">
                <section id="home" className="section welcome-area inner-area bg-overlay min-vh-100 overflow-hidden">
                    <div className="container h-100">
                        <div className="row align-items-center justify-content-center h-100">
                            <div className="col-12 col-md-7">
                                <div className="welcome-intro error-area text-center">
                                    <h2 className="text-white">{stringsTranslate(words.words.thank_you)}</h2>
                                    <h3 className="text-white my-4">{stringsTranslate(words.words.redirected_after3)}</h3>
                                    {/*<a href={paymentLink} className="btn sApp-btn text-uppercase">{"Go to Homepage"}</a>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );

}
