import React, {useState} from 'react';
import {hasAValue} from "../uitls/SharedFunctions";
import {addInterestedPeopleCall, authCarCall, authInterestedUserCall, checkInterestedUserCall} from "../api/ApiCalls";
import {toast} from "react-toastify";
import {appErrorMessages, toastOptions} from "../config/constants";
import {words} from "../locales/Words";
import {stringsTranslate} from "../locales/CustomTranslater";


export default function ImInterestedForm({vehicle, setInterested}) {

    const [name, setName] = useState(undefined)
    const [email, setEmail] = useState(undefined)
    const [tel_number, setTel_number] = useState(undefined)
    const [code, setCode] = useState(undefined)
    const [showCodeVerification, setShowCodeVerification] = useState(false)

    return (
        <div id="contact-form " className={"form-background-dashboard col-12 p-lg-3 p-3"}>
            <div className="contact-top">
                {!showCodeVerification && <h3 className="contact-title text-primary mb-4">{stringsTranslate(words.words.let_the_owner_know)}</h3>}
                {showCodeVerification && <h3 className="contact-title text-primary mb-4">{stringsTranslate(words.words.fill_in_the_code_that_you_received)}</h3>}
            </div>
            <div className="container">
                {!showCodeVerification && <div className="row">

                    <div className="col-md-12 col-lg-12 col-sm-12">
                        {/*Name */}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary"
                                        type="button"><i className="fas fa-user"/>
                                </button>
                            </div>
                            <input type="name" className="form-control input-layout fw-5"
                                   name="name"
                                   placeholder={stringsTranslate(words.words.name)} required="required" maxLength={30}
                                   onChange={(val) => setName(val.target.value)}/>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12">
                        {/*Email*/}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary"
                                        type="button"><i className="fas fa-envelope-open"/>
                                </button>
                            </div>
                            <input type="email" className="form-control input-layout fw-5" name="email"
                                   placeholder={stringsTranslate(words.words.email)} required="required" maxLength={30}
                                   onChange={(val) => setEmail(val.target.value.toString().toLowerCase())}/>
                        </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12">
                        {/*Mobile number*/}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary"
                                        type="button"><i className="fas fa-mobile"/>
                                </button>
                            </div>
                            <input type="tel" className="form-control input-layout fw-5" name="mobile"
                                   placeholder={stringsTranslate(words.words.tel_number)} required="required" maxLength={25}
                                   onChange={(val) => setTel_number(val.target.value)}/>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-bordered w-100 mt-3 fw-5" onClick={() => addInterestedPeople()}>{stringsTranslate(words.words.send)}</button>
                    </div>
                </div>}

                {showCodeVerification && <div className="row">

                    <div className="col-md-12 col-lg-12 col-sm-12">
                        {/*Code */}
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <button className="btn btn-outline-secondary"
                                        type="button"><i className="fas fa-user"/>
                                </button>
                            </div>
                            <input type="text" className="form-control input-layout fw-5"
                                   name="code"
                                   placeholder="Code" required="required" maxLength={6}
                                   onChange={(val) => setCode(val.target.value)}/>
                        </div>
                    </div>

                    <div className="col-12">
                        <button className="btn btn-bordered w-100 mt-3 fw-5" onClick={() => auth()}>{stringsTranslate(words.words.check_code)}</button>
                    </div>
                </div>}
            </div>
        </div>
    );

    function addInterestedPeople() {

        let data = {name, email, tel_number, vehicle}
        if (hasAValue(name) && hasAValue(email) && hasAValue(tel_number)) {
            addInterestedPeopleCall(data).then(results => {
                toast.success(results.message, toastOptions);
                setShowCodeVerification(true)
            }).catch(error => {
                if (hasAValue(error.response)) {
                    toast.error(error.response.data.error, toastOptions);

                }
            })
        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions);
        }
    }

    function auth() {
        let data = {email, code}
        if (hasAValue(code)) {
            authInterestedUserCall(data).then(success => {
                toast.success(success.message, toastOptions);
                setInterested(false)
                setShowCodeVerification(false)
                setEmail(undefined)
                setTel_number(undefined)
                setName(undefined)
            }).catch(error => {
                toast.error(error.response.data.error, toastOptions);
            })
        } else {
            toast.error(stringsTranslate(words.words.all_fields_are_required), toastOptions);
        }
    }
}
