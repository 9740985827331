import React from "react";


export default function ScrollToTopComponent() {

    return (
        <div id="scrollUp" title="Scroll To Top">
            <i className="fas fa-arrow-up" />
        </div>
    );
}
